<template>
  <v-app>
    <v-navigation-drawer
      v-if="login.auth"
      v-model="drawer"
      absolute
      temporary
      app
      clipped
    >
      <Menu />
    </v-navigation-drawer>

    <v-card v-if="login.auth">
      <v-toolbar dense color="grey darken-4" class="toolbar white--text">
        <v-app-bar-nav-icon class="white--text" @click="drawer = true" />
        <div class="d-flex align-center">
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          {{ isIdle }}
        </div>
        <v-spacer />
        <v-btn v-if="login.auth" icon dark @click.prevent="logoutMessage">
          <v-icon> mdi-logout-variant </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-main>
      <v-container>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>

    <v-footer app padless>
      <v-row dense>
        <v-col cols="6">
          <small
            v-if="login.auth"
            v-text="login.email"
            class="pl-1 font-weight-bold"
          />
        </v-col>
        <v-col cols="6" class="text-right">
          <small v-text="version" class="pr-1" />
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { URL } from "./control";
import { logOut } from "./requests/authRequest";
import Menu from "./components/Menu.vue";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      title: "Sinergia Médica",
      drawer: false,
      login: this.$store.getters.getLogin,
      version: "v1.24.10.09",
      url: URL,
    };
  },
  methods: {
    logoutMessage() {
      this.$swal
        .fire({
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Sí",
          icon: "warning",
          text: `¿Confirma cerrar la sesión?`,
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.logout();
          }
        });
    },
    logout() {
      logOut(this.login.token).then((response) => {
        this.$store.dispatch("logOutAction");

        if (response.success) {
          window.location.assign("/login");
        } else {
          console.log(response.message);

          this.$swal.fire({
            showConfirmButton: false,
            icon: response.success ? "success" : "error",
            text: response.message,
            timer: 2000,
          });
        }
      });
    },
  },
  mounted() {
    this.url = this.url.split("/");
    this.url = this.url[this.url.length - 2];
    this.version = `${this.url} | ${this.version}`;
  },
  computed: {
    isIdle() {
      if (
        this.login.auth &&
        this.$store.state.idleVue &&
        this.$store.state.idleVue.isIdle
      ) {
        this.logout();
      }
    },
  },
};
</script>

<style>
.toolbar {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.v-main {
  background-color: #f8f9fa;
}

.swal2-html-container,
.swal2-styled {
  font-family: "Roboto" !important;
}
</style>
